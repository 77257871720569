// 接口错误
export interface resError {
  code: string;
  message: string;
}

// 接口公用结构
export interface CommonRes {
  requestId: string;
  error?: resError;
}

// 无额外参数的接口请求
export type NoParamReq = undefined;

// 分页器
export interface PageType {
  pageSize?: number;
  pageNo?: number;
  offset?: number;
  limit?: number;
  totalCount?: number;
}

// 字典类型
export type SystemDictType = {
  dictName: string;
  dictCode: string;
  dictValue: string;
  fileViews: {
    fileUrl: string;
    fileId: string;
  }[];
  TimbreStyle: string;
  TimbreType: string;
};

// 背景裁剪参数
export interface ClipRes {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  width: number;
  height: number;
  clipBase64: string;
}

// 数智人类型
export type virtualmanType = {
  dictName: string;
  dictCode: string;
  dictValue: string;
  fileViews: {
    fileUrl: string;
    fileId: string;
  }[];
};

export enum AdminTypes {
  // 1：可管理主账号列表，2：可管理用户列表，3.普通用户
  MANAGE_BUSINESS_ACCOUNT = 1,
  MANAGE_SUB_ACCOUNT = 2,
  NORMAL = 3,
}
