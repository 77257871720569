import React from 'react';
import { connect } from 'react-redux';
import ErrorPage from '../../components/ErrorPage';
import { useTranslation } from 'react-i18next';

type IProps = {};
const Container: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  return <ErrorPage code="404" msg={t('页面未找到')} />;
};

const error404 = connect((state) => state)(Container);
export default error404;
