import i18n from 'utils/i18n';
import { message } from 'antd';
import dayjs from 'dayjs';

import { ClipRes } from 'types';
const { t } = i18n;
const utils = {
  fixSysPageUrl: (url: string) => `${url}`,
  weakDeepCopy: (obj: any) => JSON.parse(JSON.stringify(obj)),
  throttle(fn: Function, wait: number) {
    let timer: any = null;
    return function () {
      // eslint-disable-next-line prefer-rest-params
      const args: any = arguments;
      if (!timer) {
        timer = setTimeout(() => {
          fn.apply(this, args);
          timer = null;
        }, wait);
      }
    };
  },
  // json小驼峰格式化
  jsonFormat(target: any, type: 'camel' | 'pascal'): any {
    // 定义一个变量
    let result: any;
    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
      // 如果是一个数组的话
      if (Array.isArray(target)) {
        result = target.map((i) => utils.jsonFormat(i, type));
        // result = []; // 将result赋值为一个数组，并且执行遍历
        // // 递归克隆数组中的每一项
        // for (const i in target) result.push(utils.jsonFormat(target[i], type));
        // 判断如果当前的值是null的话；直接赋值为null
      } else if (target === null) {
        result = null;
        // 判断如果当前的值是一个RegExp对象的话，直接赋值
      } else if (target.constructor === RegExp) {
        result = target;
      } else {
        // 否则是普通对象，直接for in循环，递归赋值对象的所有值
        result = {};
        Object.keys(target).forEach((i) => {
          const key = type === 'camel' ? utils.camelFormat(i) : utils.pascalFormat(i);
          result[key] = utils.jsonFormat(target[i], type);
        });
        // for (const i in target) {
        //   const key = type === 'camel' ? utils.camelFormat(i) : utils.pascalFormat(i);
        //   result[key] = utils.jsonFormat(target[i], type);
        // }
      }
      // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
      result = target;
    }
    // 返回最终结果
    return result;
  },
  camelFormat(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  },
  pascalFormat(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  /**
   * 保留n位小数，无小数取整
   * @param {Number} value
   * @param {Number} n
   */
  formatFloat(value: number, n: number) {
    return Math.round(value * 10 ** n) / 10 ** n;
  },
  async getImageRect(src: string, withOrigin?: boolean, withTimestamp?: boolean) {
    const img = new Image();
    if (!withOrigin) img.crossOrigin = '';
    return new Promise<HTMLImageElement>((re) => {
      img.onload = async function () {
        re(img);
      };
      img.src = withTimestamp ? `${src}?timestamp=${new Date().getTime()}` : src;
    });
  },
  async clipBackImage(img: HTMLImageElement, width: number, height: number, scale?: number) {
    // 创建裁切用canvas
    const resizeCanvas = document.createElement('canvas');
    resizeCanvas.width = width / 1;
    resizeCanvas.height = height / 1;
    const resizeCanvasContext = resizeCanvas.getContext('2d');

    return new Promise<ClipRes>((re) => {
      let sx = 0;
      let sy = 0;
      let sWidth = img.width;
      let sHeight = img.height;
      const dx = 0;
      const dy = 0;
      const dWidth = width;
      const dHeight = height;
      const WHRatio = width / height;
      const imgWHRatio = img.width / img.height;
      // 宽高比小于视频分辨率宽高比
      if (imgWHRatio < WHRatio) {
        sHeight = img.width / WHRatio;
        sy = (img.height - sHeight) / 2;
        sWidth = img.width;
        // 宽高比大于视频分辨率宽高比
      } else if (imgWHRatio > WHRatio) {
        sWidth = img.height * WHRatio;
        sx = (img.width - sWidth) / 2;
        sHeight = img.height;
      }
      resizeCanvasContext?.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
      const base64 = resizeCanvas.toDataURL('image/jpeg', scale);
      re({ width, height, x1: sx, y1: sy, x2: sx + sWidth, y2: sy + sHeight, clipBase64: base64 });
    });
  },
  async dataURItoBlob(dataURI: string) {
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // mime类型
    const byteString = atob(dataURI.split(',')[1]); // base64 解码
    const arrayBuffer = new ArrayBuffer(byteString.length); // 创建缓冲数组
    const intArray = new Uint8Array(arrayBuffer); // 创建视图

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], {
      type: mimeString,
    });
  },
  checkBackgroundClip: (backgroundUrl?: string, resolutionValue?: string) => {
    const image = new Image();
    image.src = backgroundUrl || '';
    const timer = setInterval(() => {
      if (image.width > 0 || image.height > 0) {
        clearInterval(timer);
        const imgResolution = `${image.width}x${image.height}`;
        console.log(31, imgResolution, resolutionValue, imgResolution === resolutionValue);
        if (imgResolution !== resolutionValue) {
          message.info(t('当前图片和分辨率比例不一致，已自动裁剪适配'));
        }
      }
    }, 50);
  },
  /**
   * 格式化文件大小
   * @param {Number | String} fileSize bytes
   * @return {String} fileSize+unit
   */
  renderFileSize: (fileSize: number | string) => {
    const unitArr = ['B', 'K', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let index = 0;
    const value = Number(fileSize);
    index = Math.floor(Math.log(value) / Math.log(1024));
    const size = (value / 1024 ** index).toFixed(2);
    return size + unitArr[index];
  },
  trim: (value: any) => (typeof value === 'string' ? value.trim() : value),
  /**
   * 日期格式化
   * @param {*} time
   */
  formatTime: (date?: string) => {
    if (!date) return '-';
    // const dateStr = date.split(' ');
    // const strGMT = `${dateStr[0]} ${dateStr[1]} ${dateStr[2]} ${dateStr[5]} ${dateStr[3]} GMT+0800`;
    const time = new Date(+date * 1000);
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    const dates = time.getDate();
    const hour = time.getHours();
    const minute = time.getMinutes();
    const second = time.getSeconds();
    return t('${year}年${month}月${dates}日 ${hour}:${minute}:${second}', {
      year,
      month: utils.transferNumToString(month),
      dates: utils.transferNumToString(dates),
      hour: utils.transferNumToString(hour),
      minute: utils.transferNumToString(minute),
      second: utils.transferNumToString(second),
    });
  },
  transferNumToString(num: number) {
    return num < 10 ? `0${num}` : num;
  },
  dateFormat: (timestamp: number, dateSchema: string) =>
    !timestamp ? '--' : dayjs(timestamp).format(dateSchema || 'YYYY/MM/DD HH:mm:ss'),
  // 获取视频宽高,支持src和file
  async getVideoRect(v: any) {
    const video = document.createElement('video');
    video.preload = 'metadata';
    return new Promise((re) => {
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const width = video.videoWidth;
        const height = video.videoHeight;
        re &&
          re({
            width,
            height,
          });
      };
      video.src = typeof v === 'string' ? v : URL.createObjectURL(v);
    });
  },
  // 十进制转Hex色值
  transNumToHex(num: number) {
    const val = num.toString(16).padStart(6, '0').toLowerCase();
    return `#${val}`;
  },
  // Hex色值转十进制
  transHexToNum(hex: string) {
    const val = hex.replace('#', '');
    return parseInt(val, 16);
  },
  urlParse: (str = '') => {
    const keyValueArray = str.split('&').map((item) => item.split('='));
    const urlParams: Record<string, string> = {};
    keyValueArray.forEach(([k, v]) => {
      urlParams[k] = v;
    });
    return urlParams;
  },
  isValidUrl(str: string) {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i',
    );
    return pattern.test(str);
  },
  /**
   * 计算字符串所占的内存字节数，使用UTF-8的编码方式计算，
   * @param  {String} str
   * @return {Number} total
   */
  getLengthOfUtf8(str: string) {
    let total = 0;
    let charCode;
    let i;
    let len;
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i);
      if (charCode <= 0x007f) {
        total += 1;
      } else if (charCode <= 0x07ff) {
        total += 2;
      } else if (charCode <= 0xffff) {
        total += 3;
      } else {
        total += 4;
      }
    }
    return total;
  },
  transformXml2Text(ssml: string) {
    return ssml.replace(/<\/?.+?>/gi, '');
  },
  // 根据时间范围，获取横轴日期组
  getChartDateRangeData([startTime, endTime]: number[]) {
    const xData = [];
    if (endTime - startTime > 24 * 60 * 60 * 1000) {
      let temTime = startTime;
      while (temTime < endTime) {
        xData.push(dayjs(temTime).format('YYYY-MM-DD'));
        temTime += 24 * 60 * 60 * 1000;
      }
    } else {
      let i = 0;
      while (i < 24) {
        xData.push(`${i < 10 ? `0${i}` : i}:00`);
        i += 1;
      }
    }
    return xData;
  },
  // 毫秒转为分钟
  transformStoM(v: number) {
    if (!v) return 0;
    const num = v / 60000;
    return Number(num.toFixed(2));
  },
  /**
   * 过滤ssml中的全局语速标签<prosody></prosody>
   * @return {string} ssmlWithoutGlobalSpeed
   * **/
  filterGlobalSpeed(ssml: string) {
    return ssml.replace(/<prosody rate="(.[^local]+?)">(.+?)<\/prosody>/g, ($1, $2, $3) => $3);
  },
  // 将错误提示前的英文冒号去掉
  formatErrorMessage(msg: string) {
    return msg ? msg.replace(/^[a-zA-Z\s]+[:：]+/, '') : '';
  },
  /**
   * 获取 location hash 参数
   * @param {string} variable 参数名
   * @returns {string} 参数值
   */
  getIframeQueryVariable(variable: string) {
    const query = window.location.search.substring(1);

    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return '';
  },
  getQueryVariable(variable: string) {
    const query = window.location.hash.split('?');

    if (query.length < 2) {
      return '';
    }

    const vars = query[1].split('&');

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return decodeURI(pair[1]);
      }
    }
    return '';
  },
  getCurrentTimeString() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    const millisecond = String(now.getMilliseconds()).padStart(3, '0');
    return `${year}_${month}_${day}_${hour}_${minute}_${second}_${millisecond}`;
  },
};

export default utils;
