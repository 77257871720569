// import Cookies from 'js-cookie';
/**
 * 加载方式是否为微应用
 */
declare const window: Window & { __POWERED_BY_QIANKUN__: any };
// eslint-disable-next-line no-underscore-dangle
export const isMicroApp = window.__POWERED_BY_QIANKUN__;

export const isPrivate = process.env.REACT_APP_ENVIRONMENT === 'private';
// export const isPrivate = Cookies.get('lang')
//   ? Cookies.get('lang') === 'en'
//   : process.env.REACT_APP_ENVIRONMENT === 'private';
