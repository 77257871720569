import i18n from 'utils/i18n';
import { Dispatch } from 'react';
import {
  CHANGE_PLATFORM_INFO,
  CHANGE_PROJECT_INFO,
  CHANGE_EDITING_RESOURCE,
  CHANGE_USER_PERMISSION,
  CHANGE_USER_INFO,
} from './action_types';
import { getImageDetail, SceneInfo } from 'apis';
import store from 'redux/store';
import { CommonInfo, ProjectInfo, UserInfo, EditingResoureInfo } from 'redux/types';
const { t } = i18n;
interface ChangeCommonParams extends CommonInfo {
  type: CHANGE_PLATFORM_INFO;
}

export type All = ChangeCommonParams;

export const changeCommonParams = (params: CommonInfo) => (dispatch: Dispatch<All>) => {
  // console.log('commonParams change:', params);
  dispatch({ type: CHANGE_PLATFORM_INFO, ...params });
};

// 清空projectInfo
export const clearProjectInfo = () => {
  store.dispatch({ type: CHANGE_PROJECT_INFO, projectInfoInit: false });
};

// 更新projectInfo
export const changeProjectInfo = (data: ProjectInfo) => {
  store.dispatch({ type: CHANGE_PROJECT_INFO, ...store.getState().projectInfo, ...data });
};

// 拉取projectInfo
export const fetchProjectInfo = (newVirtualmanKey?: string, newSceneInfo?: SceneInfo) => {
  const { reqHeader } = store.getState().commonInfo;
  const virtualmanKey = newVirtualmanKey || store.getState().projectInfo.virtualmanKey;
  const sceneInfo = newSceneInfo || store.getState().projectInfo.sceneInfo;
  if (reqHeader.uin && !virtualmanKey) {
    console.log(t('获取projectInfo失败，virtualmanKey为空'));
    return;
  }
  // redux中virtualmanKey为内部系统接口请求公用参数，保持最新，
  virtualmanKey &&
    changeProjectInfo({
      virtualmanKey,
      projectInfoInit: newVirtualmanKey ? false : store.getState().projectInfo.projectInfoInit,
    });
  getImageDetail({}, true)
    .then((imageDetail) => {
      const {
        virtualmanInstanceName,
        virtualmanTypeCode,
        imageInfo: {
          virtualmanName,
          virtualmanResourceCode,
          resolutionValue,
          driverTypes,
          repaySource,
          virtualmanResourceId,
          modelSource,
          maxConcurrent,
          assetsType,
          anchorCode,
          archVersion,
          virtualmanTypeV2,
          modelUrl,
          engineUrl,
        },
        schemeSelection,
        botKey,
        botKeyBindType,
        speechInfo: { timbreCode, timbreName, volume, emotionCategory, emotionIntensity, platformSource },
        botBizId,
      } = imageDetail;
      // todo
      // const schemeSelection = 2;
      // const assetsType = 'unity';
      changeProjectInfo({
        virtualmanKey,
        virtualmanName,
        virtualmanResourceCode,
        virtualmanTypeCode,
        virtualmanResourceId,
        virtualmanInstanceName,
        resolutionValue,
        driverTypes,
        repaySource,
        modelSource,
        botKey,
        projectInfoInit: true,
        botKeyBindType,
        sceneInfo,
        timbreCode,
        maxConcurrent,
        schemeSelection,
        assetsType,
        anchorCode,
        archVersion,
        timbreName,
        volume,
        emotionCategory,
        emotionIntensity,
        platformSource,
        virtualmanTypeV2,
        botBizId,
        modelUrl,
        engineUrl,
      });
    })
    .catch((res) => {
      if (res.code === 'ResourceNotFound') {
        if (store.getState().commonInfo.platformType === 'virtualman') {
          // 回跳首页
          clearProjectInfo();
          window.location.replace('/ivh');
        }
      } else if (res.code === 'ResourceInsufficient.InstanceNotSetResource') {
        // 未设置形象 - 三方接入
        changeProjectInfo({ virtualmanKey: '', projectInfoInit: true });
      }
    });
};

// 更新编辑中形象资源信息
export const changeEditingResource = (data: EditingResoureInfo) => {
  store.dispatch({ type: CHANGE_EDITING_RESOURCE, editingResoureInfo: data });
};

// 更新用户权限
export const changeUserPermissions = (data: string[]) => {
  store.dispatch({ type: CHANGE_USER_PERMISSION, userPermissions: data });
};

// 更新登陆用户信息
export const changeUserInfo = (data: UserInfo) => {
  store.dispatch({ type: CHANGE_USER_INFO, userInfo: data });
};
