import React from 'react';
import { Spin } from 'antd';
import './index.scss';

const IvhLoading = () => {
  return (
    <div className="ivh-loading">
      <Spin tip="Loading..." />
    </div>
  );
};

export default React.memo(IvhLoading);
