import { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Dropdown, Menu, Avatar, Space, Divider, type MenuProps, Button } from 'antd';
import { Link } from 'react-router-dom';

import { useLocation } from 'react-router';
import classNames from 'classnames';

import { checkAuthCookie, getCloudLoginUrl, getCloudLogoutUrl } from 'utils/auth';
import { getUserInfo, getSecret, describeStatisticAdminUins } from 'apis/index';
import { menus } from 'routers';
import { changeUserInfo } from 'redux/action_creators';
import { IframeConfig, UserInfo } from 'redux/types';
import { AdminTypes } from 'types';

import avater from '../../assets/images/user@2x.png';
import developerIcon from '../../assets/images/user.png';
import shopIcon from '../../assets/images/shop.png';
import logoutIcon from '../../assets/images/logout.png';
import assetsIcon from '../../assets/images/assets-management-menu-icon.png';
import permissionIcon from '../../assets/images/permission.png';
import enLogo from 'assets/images/en/01_Tcloud_logo_Eng.png';
import { LayersIcon, ChevronDownIcon, UserAvatarIcon, VideoIcon, ChartIcon } from 'tdesign-icons-react';
import { permissionIdMap } from 'utils/const';
import { isPrivate } from 'utils/env';
import utils from 'utils';
import i18n from 'utils/i18n';
import Cookie from 'js-cookie';
import { DownOutlined } from '@ant-design/icons';
const { Header } = Layout;
const auth = checkAuthCookie();
const { t } = i18n;
const backgroundConfig =
  i18n.language === 'en'
    ? {
        backgroundImage: `url(${enLogo})`,
        width: '178px',
        height: '28px',
        backgroundSize: 'contain',
      }
    : {};
export const Logo = memo(() => {
  const [defaultLogo, setDefaultLogo] = useState('');
  const configData = useSelector((state: { iframeConfig: IframeConfig }) => state.iframeConfig);
  useEffect(() => {
    if (utils.getIframeQueryVariable('iframe') === 'true') {
      window.addEventListener('message', function (event) {
        if (event.data?.includes?.('iframeConfig')) {
          try {
            setDefaultLogo(configData.logoUrl);
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }, []);
  return (
    <div className="header-logo-container">
      <a
        href={
          i18n.language === 'en'
            ? 'https://www.tencentcloud.com/'
            : (process.env.REACT_APP_BASE_URL || '').replace('/ivh', '')
        }
      >
        <div
          className="icon-logo"
          style={
            defaultLogo
              ? {
                  backgroundImage: `url(${defaultLogo})`,
                }
              : backgroundConfig
          }
        />
      </a>
      <Link to="/">
        <div className="project-name">{t('数智人平台')}</div>
      </Link>
    </div>
  );
});

const onLogin = () => {
  const loginUrl = getCloudLoginUrl({ project: 'virtualman' });
  window.location.href = loginUrl;
};

if (!auth) onLogin();

const onLogout = () => {
  const logoutUrl = getCloudLogoutUrl({ project: 'virtualman' });
  window.location.href = logoutUrl;
};
const langList = [
  { label: 'English', key: 'en' },
  {
    label: t('简体中文'),
    key: 'cn',
  },
];
const User = memo(() => {
  const [uin, setUin] = useState(auth?.uin);
  const changeLang = ({ key }: { key: string }) => {
    Cookie.set('lang', key);
    window.location.reload();
  };
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  useEffect(() => {
    if (uin) {
      getUserInfo().then((res) => {
        setUserInfo(res.userInfo);
        changeUserInfo(res.userInfo);
      });
    }
  }, [uin]);

  if (auth) {
    const menu = useMemo(
      () => (
        <div className="ivh-user-menu">
          <div className="user-nick">{userInfo?.name || ''}</div>
          <Divider className="user-menu-divider" />
          <Menu selectable={false}>
            <Menu.Item key="developer" className="user-menu-item">
              <Space size="small">
                <img src={developerIcon} className="user-menu-icon"></img>
                <a
                  href={`https://console.${isPrivate ? 'tencentcloud' : 'cloud.tencent'}.com/developer`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('账号信息')}
                </a>
              </Space>
            </Menu.Item>
            <Menu.Item key="overview" className="user-menu-item">
              <Space size="small">
                <img src={shopIcon} className="user-menu-icon"></img>
                <a
                  href={`https://console.${isPrivate ? 'tencentcloud' : 'cloud.tencent'}.com/cam/overview`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('访问管理')}
                </a>
              </Space>
            </Menu.Item>
            {userInfo?.adminTypes?.find((item) => item === AdminTypes.MANAGE_SUB_ACCOUNT) && (
              <Menu.Item key="overview" className="user-menu-item">
                <Space size="small">
                  <img src={permissionIcon} className="user-menu-icon" style={{ width: 16, height: 16 }}></img>
                  <a
                    href={`https://xiaowei.${
                      isPrivate ? 'tencentcloud.com/index.html' : 'cloud.tencent.com/'
                    }#/admin-user`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('用户权限管理')}
                  </a>
                </Space>
              </Menu.Item>
            )}
            <Menu.Item key="asserts" className="user-menu-item">
              <Space size="small">
                <img src={assetsIcon} className="user-menu-icon"></img>
                <Link to="/asserts_management">{t('资产管理')}</Link>
              </Space>
            </Menu.Item>
          </Menu>
          <Divider className="user-menu-divider" />
          <Menu selectable={false}>
            <Menu.Item key="logout" onClick={onLogout} className="user-menu-item">
              <Space size="small">
                <img src={logoutIcon} className="user-menu-icon"></img>
                <span onClick={onLogout}>{t('退出登录')}</span>
              </Space>
            </Menu.Item>
          </Menu>
        </div>
      ),
      [userInfo],
    );
    return (
      <>
        <Dropdown overlay={<Menu selectedKeys={[i18n.language]} onClick={changeLang} items={langList} />}>
          <Button type="link">
            {langList.find(({ key }) => key === i18n.language)?.label || ''}
            <DownOutlined />
          </Button>
        </Dropdown>
        {!isPrivate && (
          <a
            className="header-help"
            href="https://cloud.tencent.com/document/product/1240"
            target="_blank"
            rel="noreferrer"
          >
            {t('文档')}
          </a>
        )}
        <Dropdown overlay={menu}>
          <Space size="small" align="center">
            <Avatar src={avater} className="header-avatar" />
          </Space>
        </Dropdown>
      </>
    );
  }

  return (
    <div className="login-button" onClick={onLogin}>
      {t('登录')}
    </div>
  );
});

const imageMenuItems: MenuProps['items'] = [
  ...(!isPrivate
    ? [
        {
          label: <Link to="/image_template">{t('我的数智人')}</Link>,
          key: 'image_template',
        },
        {
          label: <Link to="/image_photo">{t('标准形象相册')}</Link>,
          key: 'simage_photo',
        },
      ]
    : []),
  {
    label: (
      <Link to="/image_customization_manager" state={{ hideNavBack: true }}>
        {t('定制资产管理')}
      </Link>
    ),
    key: 'image_customization_manager',
  },
];

const imageMenus = <Menu selectable={true} items={imageMenuItems}></Menu>;

export const Nav = memo(() => {
  // 导航列表
  const navList = [
    { name: t('首页'), path: '/', key: 'home', icon: <LayersIcon className="header-nav-icon" /> },
    {
      name: t('形象设定'),
      path: '',
      key: 'image',
      icon: <UserAvatarIcon className="header-nav-icon" />,
    },
    {
      name: t('场景应用'),
      path: '/application',
      key: 'application',
      icon: <VideoIcon className="header-nav-icon" />,
    },
  ];

  const { uin = '', ownerUin, adminTypes } = useSelector((state: { userInfo: UserInfo }) => state.userInfo);
  const userPermissions = useSelector((state: { userPermissions: string[] }) => state.userPermissions);
  const [isInWhiteList, setInWhiteList] = useState<string[]>([]);
  const isAdmin = adminTypes?.includes(1);
  if (
    isAdmin ||
    isInWhiteList.includes(uin) ||
    userPermissions.includes(permissionIdMap.anchorService) ||
    userPermissions.includes(permissionIdMap.bvh)
  ) {
    navList.push({
      name: t('运营管理分析'),
      path: '/analysis',
      key: 'analysis',
      icon: <ChartIcon className="header-nav-icon" />,
    });
  }

  useEffect(() => {
    describeStatisticAdminUins().then((res) => {
      setInWhiteList(res.uins);
    });
  }, []);

  const { pathname } = useLocation();
  const pathNameStr = pathname.split('/')?.[1];

  return (
    <div className="header-nav-list">
      {navList.map((item, index) => {
        return item.key === 'image' ? (
          <Dropdown overlay={imageMenus} key={index} placement="bottomLeft">
            <div
              className={classNames([
                'nav-item',
                'nav-select-item',
                {
                  selected:
                    pathname === item.path || menus.find((menu) => menu.page === pathNameStr)?.activeNav === item.key,
                },
              ])}
            >
              {item.icon}
              {item.name}
              <ChevronDownIcon style={{ marginLeft: '16px' }} />
            </div>
          </Dropdown>
        ) : (
          <Link to={item.path} key={index}>
            <div
              className={classNames([
                'nav-item',
                {
                  selected:
                    pathname === item.path || menus.find((menu) => menu.page === pathNameStr)?.activeNav === item.key,
                },
              ])}
            >
              {item.icon}
              {item.name}
            </div>
          </Link>
        );
      })}
    </div>
  );
});

const IvhHeader = () => (
  <Header className="ivh-layout-header">
    <Logo />
    <Nav />
    <User />
  </Header>
);

export default memo(IvhHeader);
