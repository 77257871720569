import i18n from 'utils/i18n';
import React from 'react';

import './index.scss';
const { t } = i18n;
interface IErrorPageProps {
  code: '403' | '404' | '500';
  msg?: string;
}

const ErrorPage = (props: IErrorPageProps) => {
  let codeStyle;

  switch (props.code) {
    case '403':
      codeStyle = 'error-403';
      break;
    case '404':
      codeStyle = 'error-404';
      break;
    case '500':
    default:
      codeStyle = 'error-500';
      break;
  }

  return (
    <div className="error-box">
      <div className={`error-image ${codeStyle}`} />
      <div className="error-code">{props.code}</div>
      <div className="error-msg">{props.msg ? props.msg : t('发生错误')}</div>
    </div>
  );
};

export default React.memo(ErrorPage);
