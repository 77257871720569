import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import './index.scss';
interface PageTitleProps {
  isGoBack?: boolean;
  gotoUrl?: string;
  data?: object;
}

const LeftLink = (props: PageTitleProps) => {
  const navigate = useNavigate();
  const { gotoUrl, data, isGoBack } = props;
  return (
    <>
      {(gotoUrl || isGoBack) && (
        <a
          className="left-link"
          onClick={() => (isGoBack ? navigate(-1) : navigate(gotoUrl as string, { state: data }))}
        >
          <LeftOutlined />
        </a>
      )}
    </>
  );
};
export default React.memo(LeftLink);
