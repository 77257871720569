import React from 'react';
import { Layout } from 'antd';
import SiderMenu from './SiderMenu';
import SiderPreview from './SiderPreview';

const { Sider } = Layout;

const IvhSider = () => {
  return (
    <Sider className="ivh-layout-sider" width={232}>
      <SiderPreview />
      <SiderMenu />
    </Sider>
  );
};

export default React.memo(IvhSider);
