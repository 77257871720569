import Aegis from 'aegis-web-sdk';

import { isPrivate } from 'utils/env';

class Monitor {
  uin: string;
  aegis?: any;
  constructor(uin: string) {
    this.uin = uin;
    this.aegis = null;
    if (!isPrivate) {
      const u = uin.split('');
      u.shift();
      this.aegis = new Aegis({
        id: process.env.REACT_APP_Aegis_ID,
        uin: u.join(''),
        reportApiSpeed: true, // 接口测速
        api: {
          apiDetail: true,
        },
        reportAssetSpeed: false, // 静态资源测速
        spa: true, // spa 应用页面跳转的时候开启 pv 计算
      });
    }
  }

  info(args: any) {
    return this.aegis?.info(args);
  }

  infoAll(args: any) {
    return this.aegis?.infoAll(args);
  }

  error(args: any) {
    return this.aegis?.error(args);
  }

  report(args: any) {
    return this.aegis?.report(args);
  }

  destroy() {
    return this.aegis?.destroy();
  }
}
let monitor: Monitor | null = null;
export const createMonitor = (uin: string) => {
  monitor = new Monitor(uin);
};
export const getMonitor = () => {
  return monitor;
};
