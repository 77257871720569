import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { fetchProjectInfo, changeUserPermissions, changeUserInfo } from 'redux/action_creators';
import { ProjectInfo, CommonInfo } from 'redux/types';

import { Layout, Spin } from 'antd';
import * as container from './Container';

import getNavMode from './config';
import { authInfo } from 'utils/auth';
import { permissionIdMap } from 'utils/const';
import { createMonitor } from 'utils/monitor';
import { getUserPermissions, getSceneInstanceDetail, SceneSpace } from 'apis';

import './layout.scss';

const IvhLayout = () => {
  const [searchParams] = useSearchParams();
  const { virtualmanKey, sceneInfo } = useSelector((state: { projectInfo: ProjectInfo }) => state.projectInfo);
  const { platformType } = useSelector((state: { commonInfo: CommonInfo }) => state.commonInfo);

  const { pathname } = useLocation();
  const [spinning, setSpinning] = useState(true);

  const navMode = platformType === 'outer' ? 'NoNav' : getNavMode(pathname);
  const Component = container[navMode];

  const vkey = searchParams.get('vkey');
  const skey = searchParams.get('skey');

  useEffect(() => {
    if (skey && skey !== sceneInfo?.virtualmanSceneInstanceKey) {
      // 空间场景
      getSceneInstanceDetail({ virtualmanSceneInstanceKey: skey }).then((res: any) => {
        let fetchVkey = '';
        if (res.virtualmanSceneSpaces.find((space: SceneSpace) => space.virtualmanKey === vkey)) {
          // 获取指定vkey信息
          if (vkey && vkey !== virtualmanKey) fetchVkey = vkey;
        } else {
          // 未指定vkey时获取空间场景第一个子空间项目信息
          fetchVkey = res.virtualmanSceneSpaces[0].virtualmanKey;
        }
        fetchProjectInfo(fetchVkey, {
          virtualmanSceneInstanceKey: skey,
          virtualmanSceneCode: res.virtualmanSceneCode,
          virtualmanSceneSpaces: res.virtualmanSceneSpaces,
          virtualmanSceneInstanceName: res.virtualmanSceneInstanceName,
        });
      });
    } else if (vkey && vkey !== virtualmanKey) {
      // 角色服务场景——获取指定vkey信息
      fetchProjectInfo(vkey);
    }
  }, [searchParams]);

  useEffect(() => {
    if (authInfo?.uin) {
      // 待校验权限列表
      const permissionIds = Object.values(permissionIdMap);
      getUserPermissions({ permissionIds })
        .then((res) => {
          changeUserPermissions(res.permissions.map((permission) => permission.permissionId));
          setSpinning(false);
        })
        .finally(() => {
          setSpinning(false);
        });
      createMonitor(authInfo.uin);
    }
  }, []);

  return (
    <Layout className="ivh-layout">
      {spinning && (
        <div className="layout-spnning-wrapper">
          <Spin />
        </div>
      )}
      {(!skey || !!virtualmanKey) && <Component />}
    </Layout>
  );
};

export default React.memo(IvhLayout);
