import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';

// redux工具
let middleware = applyMiddleware(thunkMiddleware);
if (process.env.NODE_ENV !== 'production') {
  // dev 模式

  middleware = applyMiddleware(thunkMiddleware, createLogger());
  middleware = composeWithDevTools(middleware);
}

const { __initialState } = window;

export default createStore(reducer, __initialState, middleware);
