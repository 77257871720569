import i18n from 'utils/i18n';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import QRCode from 'qrcode';
import classNames from 'classnames';
import { Button, Modal, Tabs, Spin, Popconfirm, message } from 'antd';
import clipboardCopy from 'copy-to-clipboard';

import { getMiniAppSign, SceneInfo, getSceneTvsSign, getNewSecretDetail } from 'apis/index';
import { cartoonTypeCodes, cosPrefix, h5CosPrefix } from 'utils/const';
import { isPrivate } from 'utils/env';

import LeftLink from 'components/common/LeftLink';
import ScanIcon from 'assets/images/ic-scan@2x.png';
const { t } = i18n;
let allQrcodeSrcMap: any = {};

const SiderPreview = () => {
  const {
    virtualmanKey,
    virtualmanName,
    virtualmanInstanceName,
    resolutionValue,
    driverTypes,
    repaySource,
    virtualmanTypeCode,
    sceneInfo,
    botKey,
    maxConcurrent,
    schemeSelection,
    archVersion,
    timbreName,
  } = useSelector(
    (state: {
      projectInfo: {
        virtualmanKey: string;
        virtualmanName: string;
        virtualmanInstanceName: string;
        resolutionValue: string;
        driverTypes: string[];
        repaySource: string;
        virtualmanTypeCode: string;
        sceneInfo: SceneInfo;
        botKey: string;
        maxConcurrent: number;
        schemeSelection: number;
        archVersion: number;
        timbreName: string;
      };
    }) => state.projectInfo,
  );

  const locationState: any = useLocation().state ? useLocation() : { state: { isInit: false, type: '' } };
  const {
    state: { isInit, type },
  } = locationState;

  const url = 'https://virtual-human-1251316161.cos.ap-beijing.myqcloud.com/miniprogram/apaas-landing';
  const spaceUrl = 'https://virtual-human-1251316161.cos.ap-beijing.myqcloud.com/miniprogram/zodme-bank';
  const env = process.env.REACT_APP_ENVIRONMENT;

  const [codeVisible, setCodeVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrcodeSrcMap, setQrcodeSrcMap] = useState<any>(allQrcodeSrcMap);
  const [qrDriverType, setQrDriverType] = useState<string>('');
  const [qrDriverTypes, setQrDriverTypes] = useState<{ key: string; text: string; tip: string }[]>([]);
  const [sceneInitPromptVisible, setSceneInitPromptVisible] = useState(true);
  const [spaceSwicthVisible, setSpaceSwicthVisible] = useState(false);
  const [spaceVirtualmanKey, setSpaceVirtualmanKey] = useState(virtualmanKey);
  const [h5PreviewUrl, setH5PreviewUrl] = useState('');
  const [h5PreviewUrlBase, setH5PreviewUrlBase] = useState('');
  const { virtualmanSceneInstanceKey, virtualmanSceneSpaces, virtualmanSceneInstanceName } = sceneInfo;

  const getGptLink = (virtualmanKey: string, callback: () => void) => {
    getNewSecretDetail().then(({ customerSecrets }) => {
      if (customerSecrets.length) {
        const { accessKey, secretKey } = customerSecrets?.[0];
        // 获取v5版sign
        getMiniAppSign({ appKey: accessKey })
          .then((res) => {
            if (res.sign) {
              setH5PreviewUrl(
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? `${cosPrefix}/api_h5/demo/index.html?autoMarquee=1&virtualmanKey=${virtualmanKey}&sign=${encodeURIComponent(
                      res.sign,
                    )}`
                  : `${h5CosPrefix}/api_h5/demo/index.html?autoMarquee=1&env=${process.env.REACT_APP_ENVIRONMENT}&virtualmanKey=${virtualmanKey}&appKey=${accessKey}&accessToken=${secretKey}`,
              );
            }
          })
          .finally(() => {
            callback();
          });
      }
    });
  };

  const getLink = (callback: () => void) => {
    getMiniAppSign()
      .then((res) => {
        if (res.sign) setH5PreviewUrl(`${cosPrefix}/api_h5/app/index.html?sign=${encodeURIComponent(res.sign)}`);
      })
      .finally(() => callback());
  };

  useEffect(() => {
    if (!virtualmanKey) return;

    const getH5Url = () => {
      setLoading(true);
      if (archVersion !== 1) {
        getGptLink(virtualmanKey, () => {
          setLoading(false);
        });
      } else {
        getLink(() => {
          setLoading(false);
        });
      }
    };
    getH5Url();

    // 四分钟刷新一次二维码
    let timer: ReturnType<typeof setInterval> | null = null;
    timer = setInterval(() => {
      getH5Url();
    }, 1000 * 60 * 4);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [archVersion, virtualmanKey]);

  // 获取h5预览二维码
  useEffect(() => {
    if (!h5PreviewUrl) return;

    QRCode.toDataURL(h5PreviewUrl)
      .then((src) => {
        setH5PreviewUrlBase(src);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [h5PreviewUrl]);

  useEffect(() => {
    const qrDriverTypes = [];
    if (type !== 'space' && (repaySource === 'yunxiaowei' || repaySource === 'cloudai_gpt')) {
      qrDriverTypes.push({
        key: 'h5Preview',
        text: t('对话体验'),
        tip: t('包含所有已保存内容'),
      });
    }

    if (driverTypes.includes('text')) {
      qrDriverTypes.push({
        key: repaySource === 'yunxiaowei' ? 'nlp1' : '1',
        text: t('文本驱动'),
        tip: t('包含所有已保存内容'),
      });
    }
    if (driverTypes.includes('voice') && !virtualmanSceneInstanceKey) {
      // 空间场景不显示驱动方式
      qrDriverTypes.push({ key: '3', text: t('原音驱动'), tip: t('为了确保您的良好体验，建议佩戴耳机') });
      qrDriverTypes.push({
        key: '2',
        text: t('变音驱动'),
        tip: t('变声驱动暂不支持体验不同音色；为了确保您的良好体验，建议佩戴耳机。'),
      });
    }
    setQrDriverTypes(qrDriverTypes);

    const curQrDriverType = qrDriverTypes.find((i) => i.key === qrDriverType) ? qrDriverType : qrDriverTypes[0]?.key;
    setQrDriverType(curQrDriverType);
  }, [virtualmanKey, resolutionValue, driverTypes, repaySource, type]);

  useEffect(() => {
    if (isPrivate) return;
    if (!virtualmanSceneInstanceKey) {
      // 角色服务场景
      getWxAppCode();

      // 两分钟刷新一次二维码
      let timer: ReturnType<typeof setInterval> | null = null;
      timer = setInterval(() => {
        getWxAppCode();
      }, 1000 * 60 * 1.5);

      return () => {
        if (timer) clearInterval(timer);
      };
    }

    // 空间场景
    getSpaceAppCode();
    // 5分钟刷新一次二维码
    let timer: ReturnType<typeof setInterval> | null = null;
    timer = setInterval(() => {
      getSpaceAppCode();
    }, 1000 * 60 * 4.5);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [qrDriverTypes]);

  useEffect(() => {
    setSpaceVirtualmanKey(virtualmanKey);
  }, [spaceSwicthVisible]);

  // 获取小程序预览二维码
  const getWxAppCode = () => {
    if (!!virtualmanKey && !!resolutionValue && !!driverTypes?.length) {
      setLoading(true);
      getMiniAppSign().then((res) => {
        if (res.sign) {
          allQrcodeSrcMap = {};
          qrDriverTypes.map((i) => generateQR(res.sign, i.key));
        }
      });
    }
  };

  // 获取空间场景二维码
  const getSpaceAppCode = () => {
    if (!botKey) return;
    setLoading(true);
    getSceneTvsSign({ virtualmanSceneInstanceKey }).then((res) => {
      if (res.sign) {
        allQrcodeSrcMap = {};
        qrDriverTypes.map((i) => generateQR(res.sign, i.key));
      }
    });
  };

  const generateQR = (sign: string, driverType: string) => {
    let path = '';
    if (!!virtualmanSceneInstanceKey) {
      path = `${spaceUrl}?ciphertext=${encodeURIComponent(sign)}`;
    } else {
      path = encodeURI(`${url}?sign=${sign}&resolutionValue=${resolutionValue}&env=${env}&driverType=${driverType}`);
    }
    QRCode.toDataURL(path)
      .then((src) => {
        allQrcodeSrcMap[driverType] = src;
        setQrcodeSrcMap(allQrcodeSrcMap);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeQrDriverType = (key: string) => {
    setQrDriverType(key);
  };

  const confirmSwitchSpace = () => {
    setSpaceSwicthVisible(false);
    if (spaceVirtualmanKey !== virtualmanKey) {
      const reg = new RegExp('vkey=[^&]*', 'gi');
      let url = '';
      if (location.href.indexOf('vkey') !== -1) {
        url = location.href.replace(reg, `vkey=${spaceVirtualmanKey}`);
      } else {
        url = `${location.href}&vkey=${spaceVirtualmanKey}`;
      }
      location.replace(url);
      location.reload();
    }
  };

  const onClickCodeBtn = () => {
    if (!botKey && virtualmanSceneInstanceKey) {
      Modal.confirm({
        title: t('您所选择的bot暂未配置对话'),
        content: t('请先对您该场景所关联的bot配置对话'),
      });
      return;
    }
    setCodeVisible(true);
  };

  return (
    <div className="sider-preview">
      <div className="project-Info">
        <span className="project-name" title={virtualmanSceneInstanceName || virtualmanInstanceName}>
          <LeftLink gotoUrl="/application" data={{ tab: virtualmanSceneInstanceKey ? 'space' : 'dialog' }} />
          {virtualmanSceneInstanceName || virtualmanInstanceName}
        </span>
        {!virtualmanSceneInstanceKey && (
          <span className="virtualman-name" title={virtualmanName}>
            {virtualmanName}
          </span>
        )}
        {!virtualmanSceneInstanceKey && !!timbreName && (
          <span className="virtualman-name" title={timbreName}>
            {timbreName}
          </span>
        )}
      </div>
      {/* 私有化隐藏 */}
      {!isPrivate && !!virtualmanName && !cartoonTypeCodes.includes(virtualmanTypeCode) && schemeSelection !== 2 && (
        <Button
          type="primary"
          disabled={!virtualmanKey || (!virtualmanSceneInstanceKey && !maxConcurrent && archVersion !== 2)}
          icon={<img src={ScanIcon} className="icon-preview-project" />}
          loading={loading}
          className="preview-project-btn"
          onClick={() => onClickCodeBtn()}
        >
          {t('扫码体验')}
        </Button>
      )}
      {!!virtualmanSceneInstanceKey && (
        <div className="sub-space-info">
          <div className="space-title">{t('子空间配置')}</div>
          <div className="space-name">
            {virtualmanSceneSpaces.find((space) => space.virtualmanKey === virtualmanKey)?.spaceName}
            <Popconfirm
              title={
                <div style={{ marginLeft: '-22px' }}>
                  {t(
                    '当前数智人形象、动作与话术管理均针对 当前子空间，您可通过切换子空间配置不同空间的形象动作与话术。',
                  )}
                </div>
              }
              onConfirm={() => setSceneInitPromptVisible(false)}
              visible={sceneInitPromptVisible && isInit}
              icon={<></>}
              showCancel={false}
              okText={t('知道了')}
              placement="bottomLeft"
              overlayStyle={{ width: '250px' }}
            >
              <span className="switch-space-btn" onClick={() => setSpaceSwicthVisible(true)}>
                {t('切换')}
              </span>
            </Popconfirm>
          </div>
        </div>
      )}
      <Modal
        title={t('扫码体验数智人驱动效果')}
        visible={codeVisible}
        footer={null}
        width={560}
        centered
        wrapClassName="ivh-qrcode-modal"
        onCancel={() => setCodeVisible(false)}
      >
        <Tabs
          defaultActiveKey="text"
          onChange={changeQrDriverType}
          className={classNames('drive-tabs', qrDriverTypes.length === 1 ? 'no-nav' : '')}
        >
          {qrDriverTypes?.map((i) => (
            <Tabs.TabPane tab={i.text} key={i.key}>
              <div className="qrcode-content">
                <Spin spinning={loading}>
                  {<img src={i.key === 'h5Preview' ? h5PreviewUrlBase : qrcodeSrcMap[i.key]} className="qrcode-img" />}
                </Spin>
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
        {qrDriverType === 'h5Preview' && (
          <div>
            <span className="h5-url">{h5PreviewUrl}</span>
            <Button
              type="link"
              onClick={async () => {
                clipboardCopy(h5PreviewUrl);
                message.success(t('H5应用地址复制成功'));
              }}
            >
              {t('复制')}
            </Button>
          </div>
        )}
        <div className="qrcode-des">
          {qrDriverType === 'h5Preview' ? t('使用微信扫码，体验数智人对话效果') : t('使用微信扫码，体验数智人小程序')}
        </div>
        <div className="qrcode-prompt">{qrDriverTypes.find((i) => i.key === qrDriverType)?.tip}</div>
      </Modal>
      {/* 切换子空间弹窗 */}
      <Modal
        title={t('${virtualmanInstanceName}子空间配置', { virtualmanInstanceName })}
        visible={spaceSwicthVisible}
        width={936}
        centered
        wrapClassName="ivh-space-switch-modal"
        onCancel={() => setSpaceSwicthVisible(false)}
        onOk={() => confirmSwitchSpace()}
      >
        <div className="space-prompt">
          {t(
            '请选择当前空间所配置的子空间，完成后您所设置的形象与动作、对话剧本、接入管理及数据统计均仅针对当前子空间生效，其它空间需重新切换完成设置。',
          )}
        </div>
        <div className="space-list">
          {virtualmanSceneSpaces.map((space) => (
            <div
              key={space.spaceCode}
              className={classNames('space-item', { selected: spaceVirtualmanKey === space.virtualmanKey })}
              onClick={() => setSpaceVirtualmanKey(space.virtualmanKey)}
            >
              <img src={space.projectUrl} className="img-space-project" />
              <p className="space-name">{space.spaceName}</p>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(SiderPreview);
