import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Content from './Content';
import Sider from './Sider';
import utils from 'utils';
import { useSelector } from 'react-redux';
import { UPDATE_IFRAME_CONFIG } from 'redux/action_types';
import store from 'redux/store';
import { IframeConfig } from 'redux/types';

export const MixNav = React.memo(() => {
  const configData = useSelector((state: { iframeConfig: IframeConfig }) => state.iframeConfig);

  useEffect(() => {
    if (utils.getIframeQueryVariable('iframe') === 'true') {
      window.addEventListener('message', function (event) {
        if (event.data?.includes?.('iframeConfig')) {
          try {
            const jsonConfig = JSON.parse(event.data);
            store.dispatch({ type: UPDATE_IFRAME_CONFIG, iframeConfig: jsonConfig });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }, []);
  return (
    <Layout>
      <div style={{ display: configData.hideHeader ? 'none' : 'block' }}>
        <Header />
      </div>
      <Layout className="ivh-main-layout">
        <div style={{ display: configData.hideSidebar ? 'none' : 'block' }}>
          <Sider />
        </div>
        <Layout>
          <Content />
        </Layout>
      </Layout>
    </Layout>
  );
});

export const TopNav = React.memo(() => {
  const configData = useSelector((state: { iframeConfig: IframeConfig }) => state.iframeConfig);

  useEffect(() => {
    if (utils.getIframeQueryVariable('iframe') === 'true') {
      window.addEventListener('message', function (event) {
        if (event.data?.includes?.('iframeConfig')) {
          try {
            const jsonConfig = JSON.parse(event.data);
            store.dispatch({ type: UPDATE_IFRAME_CONFIG, iframeConfig: jsonConfig });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }, []);
  return (
    <Layout>
      <div style={{ display: configData.hideHeader ? 'none' : 'block' }}>
        <Header />
      </div>
      <Layout>
        <Content />
      </Layout>
    </Layout>
  );
});

export const NoNav = React.memo(() => (
  <Layout>
    <Content />
  </Layout>
));
