/**
 * 页面布局：NoNav:无导航 || TopNav:header || MixNav:header&sider
 **/
import { isMicroApp } from 'utils/env';

const getNavMode = (pathname: string) => {
  const isIndex = [
    '/',
    // '/index',
    // '/scene_index',
    '/home',
    '/image_photo',
    '/image_template',
    '/create_image_template',
    '/application',
    '/image_customization',
    '/bvh_page',
    '/analysis',
    '/custom_sample',
    '/asserts_management',
    '/image_customization_manager',
    '/image_customization_result',
    '/custom_sample_photo',
    '/custom_sample_voice',
    '/timbre_replication_management',
    '/precheck',
  ].includes(pathname);
  if (isMicroApp) return 'NoNav';
  if (isIndex) return 'TopNav';
  return 'MixNav';
};

export default getNavMode;
