// 更改角色列表
export const CHANGE_ROLE_LIST = 'CHANGE_ROLE_LIST';
export type CHANGE_ROLE_LIST = typeof CHANGE_ROLE_LIST;

// 更改角色对应资源列表
export const CHANGE_RESOURCE_LIST = 'CHANGE_RESOURCE_LIST';
export type CHANGE_RESOURCE_LIST = typeof CHANGE_RESOURCE_LIST;

// 更改选中数智人形象资源
export const CHANGE_RESOURCE_ITEM = 'CHANGE_RESOURCE_ITEM';
export type CHANGE_RESOURCE_ITEM = typeof CHANGE_RESOURCE_ITEM;

// 更改选中数智人配置
export const CHANGE_IMAGE_CONFIG = 'CHANGE_IMAGE_CONFIG';
export type CHANGE_IMAGE_CONFIG = typeof CHANGE_IMAGE_CONFIG;

// 清空制作信息
export const CLEAR_IMAGE_CONFIG = 'CLEAR_IMAGE_CONFIG';
export type CLEAR_IMAGE_CONFIG = typeof CLEAR_IMAGE_CONFIG;

export const AUDITION_INIT = 'audition/init';
export const AUDITION_LOADING = 'audition/loading';
export const AUDITION_PLAY = 'audition/play';
export const AUDITION_PAUSE = 'audition/pause';
export const AUDITION_STOP = 'audition/stop';
export const AUDITION_PLAYING_IDX_UPDATE = 'audition/playing/idx/update';
export const AUDITION_PLAY_QUEUE_UPDATE = 'audition/play/queue/update';
export const AUDIO_PROCESS_UPDATE = 'audio/process/update';

export const CHANGE_VIDEO_RESOURCE = 'CHANGE_VIDEO_RESOURCE';
export type CHANGE_VIDEO_RESOURCE = typeof CHANGE_VIDEO_RESOURCE;

// 音视频制作编辑视频
export const CHANGE_VIDEO_EDITOR_DATA = 'CHANGE_VIDEO_EDITOR_DATA';
export const CLEAR_VIDEO_EDITOR_DATA = 'CLEAR_VIDEO_EDITOR_DATA';
