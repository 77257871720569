import { ImageSettingValue, RoleItem, AnchorPreviewItem } from '../../pages/image_configure/types';

export interface ImageConfig extends ImageSettingValue {
  driverType?: string;
  ssml?: string;
  originSsml?: string;
  imageTemplateId?: number;
  timbreCode?: string;
  anchorImage: AnchorPreviewItem;
  imagePositionType: string;
  isPullFlowPreview?: number;
  audioDriverUrl?: string;
  speed?: string;
  virtualmanResourceId?: number; // 预设数据，选定后需清空
  virtualmanCode?: string; // 预设数据，不清空翻页选定用
  showSubtitles?: boolean;
  suitColor?: string;
}

export interface RoleListInfo {
  roleList: RoleItem[];
  totalCount: number;
  offset: number;
}

export enum MAKE_TYPE {
  MAKE_TYPE_2D_HUMAN = '2d-human',
  MAKE_TYPE_2D_CARTOON = '2d-cartoon',
  MAKE_TYPE_3D = '3d',
  MAKE_TYPE_TEMPLATE = 'template',
  MAKE_TYPE_SIMPLE_SIGN_LANGUAGE = 'only-sign-language',
  MAKE_TYPE_EMBEDDED_SIGN_LANGUAGE = 'embedded-sign-language',
  MAKE_TYPE_AUDIO = 'audio',
  MAKE_TYPE_3D_AUDIO = '3DAudio',
}

export interface MakeInfoState {
  makeType: MAKE_TYPE;
}

export enum DriverType {
  TEXT = 'text',
  AUDIO = 'voice',
}

export type VideoResourceItem = {
  videoResolution: string;
  videoProportion: string;
  fileId: string;
  type: number;
  fileUrl: string;
  name: string;
  imageId: string;
  imageUrl: string;
};

export type VideoResource = {
  defaultBg?: {
    fileId: string;
    url: string;
  };
  backgroundList?: VideoResourceItem[];
  logoList?: VideoResourceItem[];
  headList?: VideoResourceItem[];
  tailList?: VideoResourceItem[];
};

// 播放状态  standby(待机) loading(加载中) playing(播放中) pause(暂停) stop(停止)
export enum PlayState {
  STANDBY = 'standby',
  LOADING = 'loading',
  PLAYING = 'playing',
  PAUSE = 'pause',
  STOP = 'stop',
}
export type AuditionState = {
  playState: PlayState;
  playQueue: any[]; // 播放列表
  playingIdx: number; // 播放指针
  total: number; // 总段数
  audioProcess: number; // 播放进度
  stopToastMsg: string; // 停止弹窗消息

  globalSpeed: 1;
  ssmlList: string[];
  timbre: string; // 音色
  timbreType: string; // 音色类型
  makeType: string; // 制作类型
  timbreUrl: string; // 试听音频url
};

export type VideoEditorDataStateType = {
  editorVisible: boolean;
  firstAndLastFrameResetSelected: boolean;
  videoTemplateType: VideoTempType;
  frames: number; // 预览视频素材的帧数
  videoStartIndex: number;
  videoEndIndex: number;
  audioDuration: number;
  audioUrl: string;
};

// 生成视频使用的算法类型
export type VideoTempType = 'Default' | 'Custom' | 'Circle';
