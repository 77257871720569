import { PageType } from '../../types/index';

export const getDefaultPageConfig = (): PageType => ({
  pageNo: 1,
  pageSize: 20,
});

export const fixPageReq = (params: any) => ({
  ...params,
  pageNo: undefined,
  pageSize: undefined,
  totalCount: undefined,
  limit: params.pageSize,
  offset: (params.pageNo - 1) * params.pageSize,
});

export const fixPageRes = (params: any) => ({
  ...params,
  pageSize: params.limit,
  pageNo: Math.ceil(params.offset / params.limit) + 1,
});

export const enumToMap = (
  enumList: any[] = [],
  { isSimple = true, key = 'key', title = 'title' }: { isSimple?: boolean; key?: string; title?: string } = {},
): any => {
  const enumMap = {};
  enumList.forEach((i) => {
    // @ts-ignore -- TODO
    enumMap[i[key]] = isSimple ? i[title] : i;
  });
  return enumMap;
};

export const curLog = console;
