// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zhCn from '../locales/zh-CN.json';
import en from '../locales/en.json';
import Cookies from 'js-cookie';
import { isPrivate } from './env';

const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zhCn,
  },
};
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources, // 會是所有翻譯資源
    fallbackLng: 'zh', // 如果當前切換的語言沒有對應的翻譯則使用這個語言
    // lng: Cookies.get('lang') || (process.env.REACT_APP_ENVIRONMENT === 'private' ? 'en' : 'cn'), // 預設語言
    lng: Cookies.get('lang') || (isPrivate ? 'en' : 'cn'), // 預設語言
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

document.title = i18n.t('数智人平台');

export const isIntlDomain =
  window.location.host.includes('intl.cloud.tencent') || window.location.host.includes('tencentcloud.com');

export default i18n;
