import i18n from 'utils/i18n';
import { combineReducers } from 'redux';
// 新页面在这里引入reducer，并加入到下方的combineReducers方法中

import {
  CHANGE_PLATFORM_INFO,
  CHANGE_PROJECT_INFO,
  CHANGE_EDITING_RESOURCE,
  CHANGE_USER_PERMISSION,
  CHANGE_USER_INFO,
  UPDATE_IFRAME_CONFIG,
} from './action_types';
import { CommonInfo, ProjectInfo, UserInfo, EditingResoureInfo, IframeConfig } from './types';

import {
  resourceItem,
  imageConfig,
  resourceList,
  roleListInfo,
  auditionInfo,
  videoResource,
  videoEditorData,
} from './bvh';
import utils from 'utils';
const { t } = i18n;
const commonState: CommonInfo = {
  platformType: '',
  platformCode: '',
  reqHeader: {},
  reqExt: {},
  toLogin: () => {},
  onImageEditStatusChange: () => {},
};

const commonInfo = (state = commonState, action: any) => {
  switch (action.type) {
    case CHANGE_PLATFORM_INFO:
      return {
        ...state,
        platformType: action.platformType || '',
        platformCode: action.platformCode || '',
        reqHeader: action.reqHeader || {},
        reqExt: action.reqExt || {},
        toLogin: action.toLogin || (() => {}),
        onImageEditStatusChange: action.onImageEditStatusChange || (() => {}),
      };
    default:
      return state;
  }
};

// 当前查看项目信息
const projectState: ProjectInfo = {
  projectInfoInit: false,
  virtualmanKey: '',
  virtualmanTypeCode: '',
  virtualmanResourceId: null,
  virtualmanName: '',
  virtualmanResourceCode: '',
  virtualmanInstanceName: '',
  resolutionValue: '',
  driverTypes: [],
  repaySource: '',
  modelSource: '',
  botKey: '',
  botKeyBindType: 0,
  sceneInfo: {
    virtualmanSceneInstanceKey: '',
    virtualmanSceneSpaces: [],
    virtualmanSceneInstanceName: '',
    virtualmanSceneCode: '',
  },
  timbreCode: '',
  maxConcurrent: 0,
  schemeSelection: undefined,
  assetsType: '',
  anchorCode: '',
  archVersion: 1,
  timbreName: '',
  volume: 0,
  emotionCategory: '',
  emotionIntensity: 0,
  platformSource: 0,
  virtualmanTypeV2: '',
  botBizId: '',
  modelUrl: '',
  engineUrl: '',
};

const projectInfo = (state = projectState, action: any) => {
  switch (action.type) {
    case CHANGE_PROJECT_INFO: {
      return {
        ...state,
        projectInfoInit: (action?.projectInfoInit as boolean) || false,
        virtualmanKey: action?.virtualmanKey || '',
        virtualmanTypeCode: action?.virtualmanTypeCode || '',
        virtualmanResourceId: action?.virtualmanResourceId || null,
        virtualmanName: action?.virtualmanName || '',
        virtualmanResourceCode: action?.virtualmanResourceCode || '',
        virtualmanInstanceName: action?.virtualmanInstanceName || '',
        resolutionValue: action?.resolutionValue || '',
        driverTypes: action?.driverTypes || [],
        repaySource: action?.repaySource || '',
        modelSource: action?.modelSource || '',
        botKey: action?.botKey || '',
        botKeyBindType: action?.botKeyBindType || 0,
        sceneInfo: action?.sceneInfo || {
          virtualmanSceneInstanceKey: '',
          virtualmanSceneSpaces: [],
          virtualmanSceneInstanceName: '',
          virtualmanSceneCode: '',
        },
        timbreCode: action?.timbreCode || '',
        maxConcurrent: action?.maxConcurrent || 0,
        schemeSelection: action?.schemeSelection || undefined,
        assetsType: action?.assetsType || '',
        anchorCode: action?.anchorCode || '',
        archVersion: action?.archVersion || 1,
        timbreName: action?.timbreName || '',
        volume: action?.volume || 0,
        emotionCategory: action?.emotionCategory || '',
        emotionIntensity: action?.emotionIntensity || 0,
        platformSource: action?.platformSource || 0,
        virtualmanTypeV2: action?.virtualmanTypeV2 || '',
        botBizId: action?.botBizId || '',
        modelUrl: action?.modelUrl || '',
        engineUrl: action?.engineUrl || '',
      };
    }
    default:
      return state;
  }
};

// 当前编辑所选形象信息
const resoureFlatState: EditingResoureInfo = {
  key: '',
  timbreCode: '',
  virtualmanResourceId: 0,
  clothesColor: '',
  colorPreviewUrl: '',
  clothesList: [],
  poseList: [],
  resolutionList: [],
  timbreList: [],
  modelSource: '',
  imagePositionType: '',
  templateCode: '',
  clothesTemplates: [],
  fuseImageType: '',
};

const editingResoureInfo = (state = resoureFlatState, action: any) => {
  switch (action.type) {
    case CHANGE_EDITING_RESOURCE: {
      return {
        ...action.editingResoureInfo,
      };
    }
    default:
      return state;
  }
};

// 用户权限列表
const userPermissionsState: string[] = [];
const userPermissions = (state = userPermissionsState, action: any) => {
  switch (action.type) {
    case CHANGE_USER_PERMISSION: {
      return [...action.userPermissions];
    }
    default:
      return state;
  }
};

// 登陆信息列表
const userInfoState: UserInfo = {};
const userInfo = (state = userInfoState, action: any) => {
  switch (action.type) {
    case CHANGE_USER_INFO: {
      return { ...action.userInfo };
    }
    default:
      return state;
  }
};

// 当前的iframe嵌入信息
const iframeConfigState: IframeConfig = {
  prefixCls: 'idh',
  hideHeader: utils.getIframeQueryVariable('iframe') === 'true', // 隐藏头部
  hideSidebar: utils.getIframeQueryVariable('iframe') === 'true', // 隐藏左边菜单
  logoUrl: 'https://tde.xiaowei.cloud.tencent.com/static/media/logo-tencent-cloud-ai.ca666d3084f20bdf19ca.png',
  applicationTab: [t('会话互动'), t('空间互动'), t('音视频播报'), t('人工坐席')], // 列表页里的tab页面的隐藏限制
  analysisTab: [t('交互数据管理'), t('播报数据管理')], // 列表页里的tab页面的隐藏限制
  theme: {
    primaryColor: '#0052d9',
    primaryColorHover: '#266fe8',
    primaryColorActive: '#0034b5',
    linkColor: '#0052d9', // 链接色
    successColor: '#00a870', // 成功色
    warningColor: '#ed7b2f', // 警告色
    errorColor: '#e34d59', // 错误色
    fontSizeBase: '14px', // 主字号
    headingColor: '#0f1829', // 标题色
    textColor: '#0f1829', // 主文本色
    textColorSecondary: 'rgba(15, 24, 41, 0.6)', // 次文本色
    disabledColor: 'rgba(15, 24, 41, 0.3)', // 失效色
    borderRadiusBase: '3px', // 组件/浮层圆角
    borderColorBase: '#d0d5dc', // 边框色
    boxShadowBase:
      '0px 3px 14px 2px rgba(15, 24, 41, 0.05), 0px 8px 10px 1px rgba(15, 24, 41, 0.06), 0px 5px 5px 3px rgba(15, 24, 41, 0.06)', // 浮层阴影

    btnDisableColor: '#fff',
    btnDisableBg: '#d4e3fc',
    btnDisableBorder: '#d4e3fc',
    // slider
    sliderRailBackgroundColor: '#dee2e8',
    sliderRailBackgroundColorHover: '#dee2e8',
    sliderDisabledColor: '#bbd3fb',
    sliderDisabledBackgroundColor: '#eaedf2',
    sliderTrackBackgroundColor: '#0052d9',
    sliderTrackBackgroundColorHover: '#0052d9',
    sliderHandleColor: '#0052d9',
    sliderHandleColorHover: '#0052d9',
    // select
    selectItemSelectedColor: '#0f1829',
    selectItemSelectedFontWeight: '500',
    selectItemSelectedBg: '#e0ebff',
    selectItemActiveBg: '#f3f3f3',
  },
  pageMinWidth: 900, // 页面的最小宽度定义
};

const iframeConfig = (state = iframeConfigState, action: any) => {
  switch (action.type) {
    case UPDATE_IFRAME_CONFIG: {
      return {
        ...action.iframeConfig,
      };
    }
    default:
      return state;
  }
};
export default combineReducers({
  commonInfo,
  projectInfo,
  editingResoureInfo,
  userPermissions,
  userInfo,
  resourceItem,
  imageConfig,
  resourceList,
  roleListInfo,
  auditionInfo,
  videoResource,
  videoEditorData,
  iframeConfig,
});
