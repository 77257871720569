import i18n from 'utils/i18n';
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Menu, Tag } from 'antd';
import type { MenuProps } from 'antd';

import { customerModules, TYPE_V2_CARTOON, permissionIdMap, lkeModules } from 'utils/const';
import { isPrivate } from 'utils/env';

import { SceneInfo, getReleaseInfo, getUserAccount } from 'apis';
const { t } = i18n;
type MenuItem = Required<MenuProps>['items'][number];

const SiderMenu = () => {
  const { pathname } = useLocation();
  const [key, setKey] = useState(pathname);
  const userPermissions = useSelector((state: { userPermissions: string[] }) => state.userPermissions);
  const releaseTimerRef = useRef<any>();
  const [releaseStatus, setReleaseStatus] = useState<number>();
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);
  const [flag, setFlag] = useState(true);
  const {
    virtualmanKey,
    repaySource,
    botKeyBindType,
    schemeSelection,
    botKey,
    anchorCode,
    archVersion,
    sceneInfo: { virtualmanSceneInstanceKey },
    virtualmanTypeV2 = '',
    botBizId,
  } = useSelector(
    (state: {
      projectInfo: {
        virtualmanKey: string;
        repaySource: string;
        botKeyBindType: number;
        schemeSelection: number;
        botKey: string;
        sceneInfo: SceneInfo;
        anchorCode: string;
        archVersion?: number;
        virtualmanTypeV2: string;
        botBizId: string;
      };
    }) => state.projectInfo,
  );

  useLayoutEffect(() => {
    setKey(pathname);
    if (pathname.indexOf('/customer_page') !== -1) {
      customerModules.forEach((customerModule) => {
        customerModule.moduleChildrens.forEach((moduleChildrens) => {
          if (pathname.indexOf(moduleChildrens.key) !== -1) setKey(moduleChildrens.key);
          return;
        });
      });
    }
    if (pathname.indexOf('/lke') !== -1) {
      lkeModules.forEach((lkeModule) => {
        if (pathname.indexOf(lkeModule.key) !== -1) setKey(lkeModule.key);
        return;
      });
    }
  }, [pathname]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const params = `${virtualmanSceneInstanceKey ? `skey=${virtualmanSceneInstanceKey}&` : ''}vkey=${virtualmanKey}`;
  const getReleaseStatus = () => {
    botBizId &&
      getReleaseInfo({ botBizId }).then(({ isUpdated, status }) => {
        let currentStatus: number;
        if ([1, 2, 5, 6, 8].includes(status)) {
          currentStatus = 2;
        } else if (![2, 5].includes(status)) {
          if (isUpdated) {
            currentStatus = 1;
          } else {
            currentStatus = 0;
          }
        } else {
          currentStatus = 0;
        }
        setReleaseStatus(currentStatus);
      });
  };
  useEffect(() => {
    return () => {
      clearInterval(releaseTimerRef.current);
    };
  }, []);
  useEffect(() => {
    if (!menuItems?.length) {
      return;
    }
    const tempMenuItems = [...(menuItems || [])];
    const newTempMenu = tempMenuItems.map((t: any) => {
      let newChildren;
      if (t?.label === i18n.t('对话管理')) {
        newChildren = t.children.map((module: any) => {
          const { defaultlabel, key } = module;
          let statusTag;
          if (key === '/lke/app/knowledge/app-config') {
            if (releaseStatus === 1) {
              statusTag = (
                <Tag
                  className="menu-tag"
                  style={{
                    backgroundColor: '#fff8f0',
                    color: '#ff8345',
                    borderColor: '#ffebda',
                    fontWeight: 400,
                  }}
                >
                  {i18n.t('待发布')}
                </Tag>
              );
            } else if (releaseStatus === 2) {
              statusTag = (
                <Tag
                  className="menu-tag"
                  style={{
                    backgroundColor: '#ecf9ff',
                    color: '#1492ff',
                    borderColor: '#d0efff',
                    fontWeight: 400,
                  }}
                >
                  {i18n.t('发布中')}
                </Tag>
              );
            }
          }
          return {
            ...module,
            label: (
              <>
                {defaultlabel}
                {statusTag}
              </>
            ),
          };
        });
        return { ...t, children: [...newChildren] };
      }
      return { ...t };
    });
    setMenuItems(newTempMenu);
  }, [releaseStatus]);
  useEffect(() => {
    console.log(params, 'params=====');
    const tempMenuItems: MenuProps['items'] = [
      {
        label: (
          <span className="idh-menu-title-content" title={t('数智人管理')}>
            {t('数智人管理')}
          </span>
        ),
        key: 'sub1',
        icon: <span className="meun-icon virtualman" />,
        children: [],
      },
    ];
    if (!isPrivate) {
      tempMenuItems.push({
        label: t('流程管理'),
        key: 'sub3',
        icon: <span className="meun-icon virtualman" />,
        children: [
          {
            label: <Link to={`sentence_list?${params}`}>{t('话术管理')}</Link>,
            key: '/sentence_list',
          },
        ],
      });
    }
    // 公有云接入管理菜单
    if (!virtualmanSceneInstanceKey && !isPrivate) {
      tempMenuItems.push({
        label: (
          <span className="idh-menu-title-content" title={t('接入管理')}>
            {t('接入管理')}
          </span>
        ),
        key: 'sub4',
        icon: <span className="meun-icon join" />,
        children:
          schemeSelection === 2
            ? [
                {
                  label: <Link to={`sdk_insert?${params}`}>{t('SDK接入')}</Link>,
                  key: '/sdk_insert',
                },
                {
                  label: <Link to={`api_detail?${params}`}>{t('获取密钥')}</Link>,
                  key: '/api_detail',
                },
              ]
            : [
                {
                  label: <Link to={`api_detail?${params}`}>{t('API接入')}</Link>,
                  key: '/api_detail',
                },
                {
                  label: <Link to={`sdk_insert_api?${params}`}>{t('SDK接入')}</Link>,
                  key: '/sdk_insert_api',
                },
                {
                  label: <Link to={`h5_insert?${params}`}>{t('H5接入')}</Link>,
                  key: '/h5_insert',
                },
              ],
      });
    }

    // 私有化接入管理菜单
    if (!virtualmanSceneInstanceKey && isPrivate) {
      tempMenuItems.push({
        label: (
          <span className="idh-menu-title-content" title={t('接入管理')}>
            {t('接入管理')}
          </span>
        ),
        key: 'sub4',
        icon: <span className="meun-icon join" />,
        children:
          schemeSelection === 2
            ? [
                {
                  label: <Link to={`api_detail?${params}`}>{t('获取密钥')}</Link>,
                  key: '/api_detail',
                },
              ]
            : [
                {
                  label: <Link to={`api_detail?${params}`}>{t('API接入')}</Link>,
                  key: '/api_detail',
                },
                // {
                //   label: <Link to={`sdk_insert_api?${params}`}>{t('SDK接入')}</Link>,
                //   key: '/sdk_insert_api',
                // },
              ],
      });
    }

    // 2D真人、3D
    if (!TYPE_V2_CARTOON.includes(virtualmanTypeV2)) {
      // 呦呦增加表情预览
      // @ts-ignore
      tempMenuItems[0].children = !virtualmanSceneInstanceKey
        ? [{ label: <Link to={`image_set?${params}`}>{t('形象设置')}</Link>, key: '/image_set' }]
        : [
            { label: <Link to={`image_configure?${params}`}>{t('形象设置')}</Link>, key: '/image_configure' },
            { label: <Link to={`action_preview_list?${params}`}>{t('动作预览')}</Link>, key: '/action_preview_list' },
          ];
    }

    // 2D卡通
    if (TYPE_V2_CARTOON.includes(virtualmanTypeV2)) {
      // @ts-ignore
      tempMenuItems[0].children.push({
        label: <Link to={`robot_config?${params}`}>{t('形象设置')}</Link>,
        key: '/robot_config',
      });
    }

    console.log(
      "!isPrivate && repaySource === 'yunxiaowei' && botKeyBindType === 0 && botKey && !virtualmanSceneInstanceKey",
      !isPrivate,
      repaySource,
      botKeyBindType,
      botKey,
      virtualmanSceneInstanceKey,
    );

    // 使用对话服务，未绑定已有对话的嵌入文本客服页面 私有化隐藏
    if (!isPrivate && repaySource === 'yunxiaowei' && botKeyBindType === 0 && botKey && !virtualmanSceneInstanceKey) {
      const productId = botKey;
      const childrens = customerModules.map((module) => {
        const { moduleChildrens, key, subTitle } = module;
        return {
          key,
          label: subTitle,
          children: moduleChildrens.map((moduleChildren) => {
            const { path, name, key } = moduleChildren;
            return {
              key,
              label: <Link to={`/customer_page/products/${productId}/-/${path}?${params}`}>{name}</Link>,
            };
          }),
          type: 'group',
        };
      });

      tempMenuItems.splice(1, 0, {
        label: t('文本对话管理'),
        key: 'sub2',
        icon: <span className="meun-icon text" />,
        children: childrens,
      });
    }

    if (!isPrivate && repaySource === 'cloudai_gpt') {
      const findItem: any = tempMenuItems.find((i) => i?.key === 'sub3');
      console.log(t('互动调优'), findItem);
      if (
        findItem &&
        findItem.children &&
        findItem.children.findIndex((i: { key: string }) => i.key === '/interact_tuning') === -1
      ) {
        findItem.children.push({
          label: <Link to={`interact_tuning?${params}`}>{t('互动调优')}</Link>,
          key: '/interact_tuning',
        });
      }
    }

    const renderMenus = async () => {
      if (flag) {
        if (!isPrivate && repaySource === 'cloudai_gpt' && botBizId && [0, 1].includes(botKeyBindType)) {
          setFlag(false);
          const res = await getUserAccount({ botBizId });
          console.log('cloudai_gpt 11111111', res);
          const { permissionList } = res;
          const gptPermissionIdMap: { [key: string]: boolean } = {
            // 大模型配置的权限
            hasLkeAppPermission:
              permissionList.findIndex((i) => i.permissionId === '*' || i.permissionId === 'lkeApp') > -1,
          };

          tempMenuItems.splice(1, 0, {
            label: t('对话管理'),
            key: 'sub2',
            icon: <span className="meun-icon text" />,
            children: lkeModules
              .filter((m) => gptPermissionIdMap[m.permissionKey])
              .map((module) => {
                const { path, name, key } = module;
                return {
                  key,
                  label: <Link to={`${path}?${params}&appid=${botBizId}`}>{name}</Link>,
                  defaultlabel: <Link to={`${path}?${params}&appid=${botBizId}`}>{name}</Link>,
                };
              }),
          });
          getReleaseStatus();
          !releaseTimerRef.current && (releaseTimerRef.current = setInterval(getReleaseStatus, 5000));
          if (!isPrivate && repaySource === 'cloudai_gpt') {
            const findItem: any = tempMenuItems.find((i) => i?.key === 'sub3');
            console.log(t('互动调优'), findItem);
            if (
              findItem &&
              findItem.children &&
              findItem.children.findIndex((i: { key: string }) => i.key === '/interact_tuning') === -1
            ) {
              findItem.children.push({
                label: <Link to={`interact_tuning?${params}`}>{t('互动调优')}</Link>,
                key: '/interact_tuning',
              });
            }
          }
        }
      }

      setMenuItems(tempMenuItems);
    };

    renderMenus();
  }, [
    repaySource,
    userPermissions,
    botKeyBindType,
    botKey,
    virtualmanSceneInstanceKey,
    virtualmanKey,
    schemeSelection,
    botBizId,
    flag,
  ]);

  return (
    <Menu
      mode="inline"
      selectedKeys={[key]}
      defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4', 'sub5']}
      className="idh-sider-menu"
      items={menuItems}
    ></Menu>
  );
};

export default React.memo(SiderMenu);
